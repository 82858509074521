<script setup lang="ts">
import { mdiDownload } from "@mdi/js";

// composables
const { $pwa } = usePwa();
</script>

<template>
  <MCard
    class="mx-2 my-6"
    variant="flat"
  >
    <div class="flex items-center justify-between p-6">
      <h2 class="text-lg md:text-2xl md:font-bold">Install App</h2>
      <MBtn
        variant="plain"
        :color="
          $pwa?.isPWAInstalled || !$pwa?.showInstallPrompt
            ? 'default'
            : 'primary'
        "
        icon
        :disabled="$pwa?.isPWAInstalled || !$pwa?.showInstallPrompt"
        @click="$pwa?.install()"
      >
        <MIcon
          :icon="mdiDownload"
          size="x-large"
        />
      </MBtn>
    </div>
  </MCard>
</template>
