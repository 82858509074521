<script setup lang="ts">
import { mdiCloudRefreshVariantOutline } from "@mdi/js";

// composables
const { online } = useNetworkCheck();
const router = useRouter();

// methods
const attemptRefresh = async () => {
  router.go(0);
};
</script>

<template>
  <MDialog
    :modelValue="!online"
    persistent
    class="max-w-xl"
  >
    <MCard variant="flat">
      <div class="h-full flex flex-col justify-between gap-y-16 p-8">
        <h1 class="text-center text-2xl">Require Network Connection</h1>

        <MBtn
          color="warning"
          block
          @click="attemptRefresh"
        >
          <MIcon
            start
            :icon="mdiCloudRefreshVariantOutline"
            size="large"
          />
          Retry
        </MBtn>
      </div>
    </MCard>
  </MDialog>
</template>
